import { FILE_RESOURCE_BASE_FRAGMENT } from '@/graphql/_Fragments/FileResource/Base';
import {
  COMMUNITY_USER_CONNECTION_FULL_FRAGMENT,
} from '@/graphql/_Fragments/CommunityUserConnection/Full';
import { COMPANY_USER_ROLE_FULL_FRAGMENT } from '@/graphql/_Fragments/CompanyUserRole/Full';
import CompanyUserRoleStatusType from '@/utils/enums/CompanyUserRoleStatusType';
import ActionRule from '@/utils/enums/ActionRule';

export const COMMUNITY_USER_ENTITY_LIST_SEARCH_FRAGMENT = `
  fragment communityUserEntityListSearchFragment on CommunityUser {
    id
    uid
    schemaCode
    __typename
    firstName
    lastName
    name
    jobTitle
    employerName
    profilePrivacyMode
    pictureFileResource {
      ...fileResourceBaseFragment
    }
    companyRoles(filter: { state: "${CompanyUserRoleStatusType.ACCEPTED}" }) {
      ...companyUserRoleFullFragment
    }
    _ourConnection(myUid: "%authUser%") {
      ...communityUserConnectionFullFragment
    }
    _actions(actions: ["${ActionRule.CAN_CONNECT}"]) {
      value
      key
    }
    _isFollowed(myUid: "%authUser%")
    score: _score
  }
  ${COMMUNITY_USER_CONNECTION_FULL_FRAGMENT}
  ${COMPANY_USER_ROLE_FULL_FRAGMENT}
  ${FILE_RESOURCE_BASE_FRAGMENT}
`;
